import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "where-is-help"
    }}>{`Where is help?`}</h1>
    <h3 {...{
      "id": "resources-for-teenagers"
    }}>{`Resources for teenagers`}</h3>
    <p>{`Encourage your teenager to check out our list of `}<GoTo to="/resources" mdxType="GoTo">{`useful resources`}</GoTo>{` for young people in their own time.`}</p>
    <h3 {...{
      "id": "resources-for-parents"
    }}>{`Resources for parents`}</h3>
    <p>{`Our `}<GoTo to="/resources" mdxType="GoTo">{`Useful Resources`}</GoTo>{` page provides a range of sources of help for you too. Check out the resources on depression and clinical anxiety for yourself or your teenager after you complete this module.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m1/4.svg" alt="child imagining" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      